import { Component, Mixins } from "vue-property-decorator";

import PaymentMethodsMixin from "../../../../common/mixins/payment-methods.mixin";

@Component
export default class PaymentMethods extends Mixins(PaymentMethodsMixin) {
  private mounted() {
    this.mountedHook();
  }
}
